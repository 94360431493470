import { EditSharp, ClearSharp, DoneSharp } from '@mui/icons-material';
import { Container, IconButton, TextField } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { DeleteComment, UpdateComment } from '../util/CommentApi';

interface CommentCardProps{
  comment: any;
  FetchData: any;
}

export default function CommentCard(props: CommentCardProps) {
  const [edit, setEdit] = useState(false);

  const { comment, FetchData } = props;
  const [commentText, setCommentText] = useState(comment['Text'].trim());
  const handleCommentDelete = async (id: string) => {
    await DeleteComment(id);
    FetchData();
  }

  const handleEditComment = () => {
    setEdit(!edit);
  }

  const handleSubmit = () => {
    const data = {
      ProjectID: comment['ProjectID'],
      CommentBy: comment['CommentBy'],
      CommentDate: moment(Date.now()).toISOString(),
      Text: commentText,
    };
    console.log(comment);
    UpdateComment(comment['CommentID'], data);
    FetchData();
    setEdit(false);
  }

  const handleCancel = () => {
    setEdit(false);
  }

  return (
    <Container key={comment['CommentID']} style={{display: 'flex', flexDirection: 'column', width: '100%', borderRadius: '10px', boxShadow: '0px 4px 4px rgba(0,0,0,0.25)', padding: '0px', marginTop: '1em', minWidth: '100%'}}>
      <div style={{ backgroundColor: '#F2F2F2', padding: '0.5em', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center'}}><span>Comment by {comment['CommentBy']} at {moment(comment['CommentDate']).format('D/M/YYYY hh:mma')}</span><span style={{right: '10px'}}><IconButton onClick={()=>handleEditComment()}><EditSharp /></IconButton><IconButton onClick={()=>handleCommentDelete(comment['CommentID'])} ><ClearSharp /></IconButton></span></div>
      {edit? (
        <>
          <div style={{display: 'flex'}}>
            <TextField id="description" name="description" value={commentText} onChange={(e)=>setCommentText(e.currentTarget.value)} fullWidth multiline/> <IconButton onClick={handleSubmit}><DoneSharp /></IconButton> <IconButton onClick={handleCancel}><ClearSharp /></IconButton>
          </div>
        </>
      ) :  (
        <span style={{ padding: '1em'}} dangerouslySetInnerHTML={{__html: comment['Text']}}/> 
      )}
    </Container>
  );
}