import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear'
import { DeleteInvoices } from "../util/InvoiceApi";
import { useEffect, useState } from "react";
import moment from "moment";
import { EditSharp } from "@mui/icons-material";
import EditInvoiceModal from "./EditInvoiceModal";

interface Data {
  InvoiceID: number;
  Name: string;
  StatusID: number;
  TypeID: number;
  Amount: number;
  IssueDate: Date;
  OldIssueDate: Date;
  PurchaseOrder: number;
  Description: string;
  ContactInfoID: number;
}

interface HeadCell {
  disabelPadding: boolean;
  id: keyof Data | 'Action';
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'Name',
    label: 'Name',
    disabelPadding: true,
    numeric: false,
  },
  {
    id: 'Description',
    label: 'Description',
    disabelPadding: true,
    numeric: false,
  },
  {
    id: 'Amount',
    label: 'Amount',
    disabelPadding: true,
    numeric: true,
  },
  {
    id: 'StatusID',
    label: 'Status',
    disabelPadding: true,
    numeric: false,
  },
  {
    id: 'IssueDate',
    label: 'Issued Date',
    disabelPadding: true,
    numeric: false,
  },
  {
    id: 'Action',
    label: '',
    disabelPadding: true,
    numeric: false
  }
]

function NewTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.disabelPadding? 'left': 'right'}
          >
            <strong className="Project_Value_Lable">{headCell.label}</strong>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface TableProps {
  rows: Data[];
  invoiceStatuses: any[];
}

export default function InvoiceTable(props: TableProps) {
  const { rows, invoiceStatuses } = props;
  const [displayRows, setDisplayRows] = useState( rows );
  const handleDelete = (row: Data) => {
    if(window.confirm('Confirm Deletion')){
      DeleteInvoices(row.InvoiceID);
      setDisplayRows(displayRows.splice(displayRows.indexOf(row),1));
    }
  }

  //Edit States
  const [open, setOpen] = useState(false);
  const [editRow, setEditRow] = useState<Data>();

  const handleEdit = (row: Data) => {
    setOpen(true);
    setEditRow(row);
  }

  useEffect(()=> {
    setDisplayRows(props.rows);
  })

  return (
    <>
      <EditInvoiceModal open={open} setOpen={setOpen} invoice={editRow} states={invoiceStatuses}/>
      <TableContainer>
        <Table>
          <NewTableHead />
          <TableBody>
            {displayRows.map((row, index) => (
              <TableRow
                tabIndex={-1}
                key={row.InvoiceID}
              >
                <TableCell>{row.Name}</TableCell>
                <TableCell>{row.Description}</TableCell>
                <TableCell>{(row.Amount.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }))}</TableCell>
                <TableCell>{invoiceStatuses && invoiceStatuses.find(el => el.StatusID === row.StatusID)?.Name}{console.log(invoiceStatuses)}</TableCell>
                <TableCell>{moment(row.IssueDate).format('D/M/YYYY hh:mma')}</TableCell>
                <TableCell style={{width: '8em'}}>
                  <IconButton onClick={()=>handleEdit(row)}>
                    <EditSharp />
                  </IconButton>
                  <IconButton onClick={()=>handleDelete(row)}>
                    <ClearIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}