import axios from "axios";
import { API_URL } from "../enviroment";
import { getToken } from "../service/firebase";

export async function CreateProject(data: any) {
  const res = await axios({
    method: 'post',
    url: `${API_URL}/projects`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    },
    data: data,
  });
  return res.data;
}

export async function GetProject(id: string) {
  const res = await axios({
    method: 'get',
    url: `${API_URL}/projects/${id}`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    }
  });
  return res.data;
}

export async function GetProjectStatuses() {
  const res = await axios({
    method: 'get',
    url: `${API_URL}/project-statuses`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    }
  });
  return res.data;
}

export async function GetProjects() {
  const res = await axios({
    method: 'get',
    url: `${API_URL}/projects`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    }
  });
  return res.data;
}

export async function UpdateProject(projectID: any, data: any) {
  const res = await axios({
    method: 'patch',
    url: `${API_URL}/projects/${projectID}`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    },
    data: data,
  });
  return res.data;
}