import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithCredential, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAdNuMw-09JjG_n_M9ilN_jLN0OWBqffBE",
  authDomain: "roadhouse-intranet.firebaseapp.com",
  projectId: "roadhouse-intranet",
  storageBucket: "roadhouse-intranet.appspot.com",
  messagingSenderId: "950945929056",
  appId: "1:950945929056:web:36d95ce618a5d0fb153005",
  measurementId: "G-XBKX5NZZ0W"
};

// Initialize Firebase 
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => signInWithPopup(auth, provider);


export function onSignIn(googleUser: any, dispatch: any) {
  // We need to register an Observer on Firebase Auth to make sure auth is initialized.
  const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
    unsubscribe();
    // Check if we are already signed-in Firebase with the correct user.
    if (!isUserEqual(googleUser, firebaseUser)) {
      // Build Firebase credential with the Google ID token.
      const credential = GoogleAuthProvider.credential(
          googleUser.getAuthResponse().id_token);

      // Sign in with credential from the Google user.
      signInWithCredential(auth, credential).catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.email;
        // // The credential that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
    } else {
      console.log(firebaseUser)
      dispatch({type: "LOGIN", payload: firebaseUser});
      console.log('User already signed-in Firebase.');
    }
  });
}

export async function getToken() {
  const token = await auth.currentUser?.getIdToken();
  return token
}

function isUserEqual(googleUser: any, firebaseUser: any) {
  if (firebaseUser) {
    const providerData = firebaseUser.providerData;
    for (let i = 0; i < providerData.length; i++) {
      if (providerData[i].providerId === GoogleAuthProvider.PROVIDER_ID &&
          providerData[i].uid === googleUser.getBasicProfile().getId()) {
        // We don't need to reauth the Firebase connection.
        return true;
      }
    }
  }
  return false;
}
