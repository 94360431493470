import React, { useReducer } from 'react';
import BasePage from './pages/BasePage';
import Login from './pages/Login';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import { AuthContext } from './util/AuthContext';
import { ThemeProvider } from '@emotion/react';
import { IntranetTheme } from './util/Theme';


function App() {
  //Handle both logged in and logged out state

  const reducer = (state: any, action: { payload: any, type: any; }) => {
    switch (action.type) {
      case "LOGIN":
        localStorage.setItem("user", JSON.stringify(action.payload));
        localStorage.setItem("token", action.payload.accessToken);
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload,
          token: action.payload.accessToken
        };
      case "LOGOUT":
        localStorage.clear();
        return {
          ...state,
          isAuthenticated: false,
          user: null
        };
      default:
        return state;
    }
  }

  const isAuthenticated = () => {
    // const user = JSON.parse(localStorage.getItem('user') || '');

    return false
  }

  const [state, dispatch] = useReducer(reducer, {isAuthenticated: isAuthenticated(), user: null, token: null});

  return (
    <>
      <ThemeProvider theme={IntranetTheme}>
        <BrowserRouter>
          <AuthContext.Provider value={{state, dispatch}}>
            {state.isAuthenticated ? (
              <BasePage />
            ): (
              <Login />
            )}
          </AuthContext.Provider>      
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
