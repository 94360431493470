import axios from "axios";
import { API_URL } from "../enviroment";
import { getToken } from "../service/firebase";

export async function CreateComment(comment: any) {
  const res = await axios({
    method: 'post',
    url: `${API_URL}/comment`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    },
    data: comment,
  });
  return res.data;
}

export async function UpdateComment(id: string, data: any) {
  const res = await axios({
    method: 'patch',
    url: `${API_URL}/comment/${id}`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    },
    data: data
  });
  return res.data;
}

export async function GetProjectComments(id: string) {
  const res = await axios({
    method: 'get',
    url: `${API_URL}/comment/project/${id}`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    }
  });
  return res.data;
}

export async function DeleteComment(id: string) {
  const res = await axios({
    method: 'delete',
    url: `${API_URL}/comment/${id}`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    }
  });
  return res.data;
}