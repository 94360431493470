import React, { FormEvent, forwardRef, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { Moment } from 'moment';
import { GetProjectStatuses, CreateProject, UpdateProject } from '../util/ProjectApi';
import { GetClients } from '../util/ClientApi';
import { getClientContact } from '../util/ClientInfo';
import { useNavigate } from 'react-router';
import { useFormState } from 'react-hook-form';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AddProjectProps {
  statuses?: Array<any> | undefined;
  clients?: Array<any> | undefined;
  edit?: boolean | undefined;
  project?: any | undefined;
}

export default function AddProjectModal(props: AddProjectProps) {
  const [open, setOpen] = useState(false);

  const [firstContactDate, setFirstContactDate] = useState<Moment | null>(null);
  const [dateStarted, setDateStarted] = useState<Moment | null>(null);
  const [dateForUAT, setDateForUAT] = useState<Moment | null>(null);
  const [lastFollowUpDate, setLastFollowUpDate] = useState<Moment | null>(null);
  const [completionDate, setCompletionDate] = useState<Moment | null>(null);
  

  const [statuses, setStatuses] = useState<Array<any>|undefined>([]);
  const [clients, setClients] = useState<Array<any>|undefined>([]);
  const [contacts, setContacts] = useState<Array<any>|undefined>([]);
  let {edit, project} = props;
  const formRef = useRef();
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (saving: boolean) => {
    console.log("SAVING",saving)
    if(saving){
      setOpen(false);
    } else {
      if(edit && hasBeenUpdated()){
        if(window.confirm('Any unsaved changes will be lost')){
          setOpen(false)
        }
      } else {
        setOpen(false);
      }
    }
  };

  const hasBeenUpdated = (): boolean => {
    let formData = new FormData(formRef.current);
    console.log(project);
    const data: any = {
      Name: formData.get('projectName'),
      ClientID: formData.get('clientName'),
      Description: formData.get('description'),
      StatusID: formData.get('status'),
      Price: formData.get('price'),
      DateStarted: dateStarted?.toISOString() || (project && project['DateStarted']),
      SalesLead: formData.get('salesLead'),
      ProjectLead: formData.get('projectLead'),
      Developers: formData.get('developers'),
      Folder: formData.get('folder'),
      DateFirstContact: firstContactDate?.toISOString() || (project && project['DateFirstContact']),
      DateLastFollowUp: lastFollowUpDate?.toISOString() || (project && project['DateLastFollowUp']),
      DateForUat: dateForUAT?.toISOString() || (project && project['DateForUat']),
      DateCompleted: completionDate?.toISOString() || (project && project['DateCompleted']),
      ContactInfoID: formData.get('clientContact'),
      TypeID: formData.get('projectType'),
      LeadDeveloper: formData.get('leadDeveloper'),
      BusinessAnalyst: formData.get('businessAnalyst'),
    }
    const keys = Object.keys(data);
    const dataobj = new Object(data);
    let changed = false
    for(var i = 0; i < keys.length; i++){
      console.log(project[keys[i]], '==', data[keys[i]], project[keys[i]] == data[keys[i]]);
      if(project[keys[i]] == data[keys[i]]) {
        continue;
       } else {
        changed = true;
       }
    }
    return changed;
  }

  useEffect(()=> {
    if(project){
      // setDateStarted(project['DateStarted']);
      // setFirstContactDate(project['DateFirstContact']);
      // setLastFollowUpDate(project['DateLastFollowUp']);
      // setDateForUAT(project['DateForUat']);
      // setCompletionDate(project['DateCompleted']);
    }
    fetchData();
  },[])

  const fetchData = async () => {
      let statusesData = await GetProjectStatuses();
      console.log(statusesData);
      setStatuses(statusesData)
      let clientsData = await GetClients();
      setClients(clientsData);
      let contactData = project? await getClientContact(project['ClientID']): [];
      setContacts(contactData);
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { nativeEvent: { submitter:{id}}} = event;
    console.log(id);
    if(window.confirm('Please Confirm Changes')){
      let formData = new FormData(event.currentTarget);
      console.log(project);
      const data = {
        Name: formData.get('projectName'),
        ClientID: formData.get('clientName'),
        Description: formData.get('description'),
        StatusID: formData.get('status'),
        Price: formData.get('price'),
        DateStarted: dateStarted?.toISOString() || (project && project['DateStarted']),
        SalesLead: formData.get('salesLead'),
        ProjectLead: formData.get('projectLead'),
        Developers: formData.get('developers'),
        Folder: formData.get('folder'),
        DateFirstContact: firstContactDate?.toISOString() || (project && project['DateFirstContact']),
        DateLastFollowUp: lastFollowUpDate?.toISOString() || (project && project['DateLastFollowUp']),
        DateForUat: dateForUAT?.toISOString() || (project && project['DateForUat']),
        DateCompleted: completionDate?.toISOString() || (project && project['DateCompleted']),
        ContactInfoID: formData.get('clientContact'),
        TypeID: formData.get('projectType'),
        LeadDeveloper: formData.get('leadDeveloper'),
        BusinessAnalyst: formData.get('businessAnalyst'),
      }
      console.log(data);
      if(edit) {
        UpdateProject(project['ProjectID'],data);
      } else {
        if(id === 'exit'){
          handleClose(true);
        } else {
          const projectData = await CreateProject(data);
          navigate(`/project/${projectData['ProjectID']}`);
        }
      }
    }
  };

  const updateClientContacts = async (value: any) => {
    const contactData = await getClientContact(value);
    setContacts(contactData);
  }

  return (
    <div>
      {edit ? (
        <Button variant="outlined" color="secondary" onClick={handleClickOpen}>Edit Project</Button>
      ): (
        <Button variant="contained" onClick={handleClickOpen} ><AddCircleIcon /> Add Project</Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={'lg'}
        TransitionComponent={Transition}
      >          
        <Box component="form" ref={formRef} onSubmit={handleSubmit}>
          {edit? (
            <DialogTitle>Edit Project</DialogTitle>
          ): (
            <DialogTitle>Add New Project</DialogTitle>
          )}
          <Divider />
          <DialogContent>
              <Grid container marginTop={1} spacing={4}>
                <Grid item xs={9}>
                  <TextField size="small" id="projectName" label="Project Name" name="projectName" defaultValue={(project && project['Name']) || ''} required fullWidth />
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" disabled label="Project ID" value={(project && project['ProjectID']) || '(new)'} fullWidth/>
                </Grid> 
                <Grid item xs={12}>
                  <TextField id="description" label="Description" name="description" defaultValue={(project && project['Description']) || ''} fullWidth multiline minRows={3}/>
                </Grid>
                <Grid item xs={6}>
                  <FormControl size='small' fullWidth>
                    <InputLabel id="status-lable">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      id="status" label="Status" name="status" value={project && project['StatusID']} required
                    >
                      {statuses && statuses.map((status, index) => (
                        <MenuItem key={index} value={status.StatusID}>{status.Name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField size="small" id="price" label="Price" name="price" defaultValue={(project && project['Price']) || ''} required fullWidth/>
                </Grid>
                <Grid item xs={6}>
                  <FormControl size='small' fullWidth>
                    <InputLabel id="status-lable">Client</InputLabel>
                    <Select
                      labelId="status-label"
                      id="clientName" label="Client Name" name="clientName" value={project && project['ClientID']} required
                      onChange={(e)=>updateClientContacts(e.target.value)}
                    >
                    {clients && clients.map((client, index) => (
                        <MenuItem key={index} value={client.ClientID}>{client.Name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl size='small' fullWidth>
                    <InputLabel id="status-lable">Client Contact Name</InputLabel>
                    <Select
                      labelId="status-label"
                      id="clientContact" label="Client Contact Name" name="clientContact" value={project && project['ClientID']} required
                    >
                      {contacts && contacts.map((client, index) => (
                        <MenuItem key={index} value={client.ClientID}>{client.Name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <h2>Project Dates</h2>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="First Contact Date"
                      value={firstContactDate || (project? project['DateFirstContact'] : null)}
                      onChange={(value) => {
                        setFirstContactDate(value);
                      }}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth/>}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Date Started"
                      value={dateStarted || (project? project['DateStarted'] : null)}
                      onChange={(value) => {
                        setDateStarted(value);
                      }}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth/>}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Date for UAT"
                      value={dateForUAT || (project? project['DateForUat'] : null)}
                      onChange={(value) => {
                        setDateForUAT(value);
                      }}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth/>}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Last Follow Up Date"
                      value={lastFollowUpDate || (project? project['DateLastFollowUp'] : null)}
                      onChange={(value) => {
                        setLastFollowUpDate(value);
                      }}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth/>}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Completion Date"
                      value={completionDate || (project? project['DateCompleted'] : null)}
                      onChange={(value) => {
                        setCompletionDate(value);
                      }}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth/>}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <h2>Project Team</h2>
                </Grid>
                <Grid item xs={6}>
                  <TextField size="small" id="salesLead" label="Sales Lead" name="salesLead" defaultValue={project && project['SalesLead']}  fullWidth/>
                </Grid>
                <Grid item xs={6}>
                  <TextField size="small" id="projectLead" label="Project Lead" name="projectLead" defaultValue={project && project['ProjectLead']}  fullWidth/>
                </Grid>
                <Grid item xs={6}>
                  <TextField size="small" id="businessAnalyst" label="Business Analyst" name="businessAnalyst" defaultValue={project && project['BusinessAnalyst']}  fullWidth/>
                </Grid>
                <Grid item xs={6}>
                  <TextField size="small" id="leadDeveloper" label="Lead Developer" name="leadDeveloper" defaultValue={project && project['LeadDeveloper']}  fullWidth/>
                </Grid>
                <Grid item xs={6}>
                  <TextField size="small" id="developers" label="Developers" name="developers" defaultValue={project && project['Developers']}  fullWidth/>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <TextField size="small" id="folder" label="Folder" name="folder" defaultValue={project && project['Folder']} fullWidth/>
                </Grid>
                <Grid item xs={6}>
                  <FormControl size="small" fullWidth required>
                    <FormLabel component="legend">Project Type</FormLabel>
                    <RadioGroup row aria-label="projectType" name="projectType" defaultValue={project && project['Type']}>
                      <FormControlLabel value="1" control={<Radio />} label="Project" />
                      <FormControlLabel value="2" control={<Radio />} label="Maintenance" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>        
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="contained" type="submit" >Save</Button>
            <Button variant="contained" id="exit" type="submit">Save & Exit</Button>
            <Button variant="outlined" color="secondary" onClick={()=>handleClose(false)}>Cancel</Button>
          </DialogActions>  
        </Box>
      </Dialog>
    </div>
  );
}