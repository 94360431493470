import { Avatar, CssBaseline, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from "@mui/system";
import React, { useContext } from "react";
import {Link, Routes, Route, useLocation} from 'react-router-dom';

import { AuthContext } from '../util/AuthContext';
import Projects from "./Projects";
import Dashboard from "./Dashboard";
import Project from "./Project";

const drawerWidth = '15em';

export default function BasePage() {
  
  const { state } = useContext(AuthContext)
  const location = useLocation();
  const isActive = (path: string) => {
    if(location.pathname === path)
      return  {color: '#DA1919', backgroundColor: '#DA191950'}
    else
      return undefined
  }

  const getProfileUrl = () => {
    if(state && state.user)
      return state.user['photoURL']
    else
      return undefined
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar>
            <Avatar  src={getProfileUrl()} />
            <p style={{ marginLeft: '1em' }}>{state && state.user && state.user['displayName']}</p>
          </Toolbar>
          <Divider />
          <List>
            <Link to="/dashboard">
              <ListItem button key='Dashboard' style={isActive('/dashboard')}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary='Dashboard' />
              </ListItem>
            </Link>
            <Link to="/projects">
              <ListItem button key='Projects' style={isActive('/projects')}>
                <ListItemIcon>
                  <InsertChartIcon />
                </ListItemIcon>
                <ListItemText primary='Projects' />
              </ListItem>
            </Link>
            <Link to="/timesheets">
              <ListItem button key='TimeSheets' style={isActive('/timesheets')}>
                <ListItemIcon>
                  <MoreTimeIcon />
                </ListItemIcon>
                <ListItemText primary='Time Sheets' />
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            <ListItem button key='MainSettings' style={isActive('/settings')}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary='Main Settings' />
            </ListItem>
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/project/:id" element={<Project />} />
          </Routes>
        </Box>
      </Box>
    </>
  )
}