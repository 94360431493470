import { createTheme } from '@mui/material/styles';

export const IntranetTheme = createTheme({
  palette: {
    primary: {
      main: '#DA1919',
    },
    secondary: {
      main: '#595F69',
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: '14px',
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          marginBottom: '0em',
        },
      }
    },
  }
});