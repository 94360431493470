import { Box, Button, Container, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import InvoiceTable from "../components/InvoiceTable";
import { GetClient } from "../util/ClientApi";
import { CreateComment, DeleteComment, GetProjectComments } from "../util/CommentApi";
import { CreateInvoices, GetInvoices, GetInvoiceStatuses } from "../util/InvoiceApi";
import { GetProject, GetProjectStatuses, UpdateProject } from "../util/ProjectApi";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment, { Moment } from 'moment';
import { getClientContact, getContact } from "../util/ClientInfo";
import AddProjectModal from "../components/AddProjectModal";
import { ClearSharp, EditSharp } from "@mui/icons-material";
import CommentCard from "../components/CommentCard";



export default function Project() {

  const { id } = useParams();

  const [project, setProject] = useState();
  const [client, setClient] = useState();
  const [invoices, setInvoices] = useState([]);
  const [comments, setComments] = useState<Array<any>>([]);
  const [statuses, setStatuses] = useState<Array<any>>([]);
  const [contactInfo, setContactInfo] = useState();
  const [clientContacts, setClientContacts] = useState<Array<any>>([]);
  const [invoiceStatuses, setInvoiceStatuses] = useState<Array<any>>([]);
  //State for Invoice Create
  const [openInvoiceCreate, setOpenInvoiceCreate] = useState(false);
  const [issueDate, setIssueDate] = useState<Moment | null>(null)
  
  const [openCommentCreate, setOpenCommentCreate] = useState(false);
  

  useEffect(()=> {
    FetchData();
  }, [])

  const FetchData = async () => {
    const projectData = await GetProject(id || '');
    setProject(projectData);
    const clientData = await GetClient(projectData['ClientID']);
    setClient(clientData);
    const invoicesData = await GetInvoices(id || '');
    setInvoices(invoicesData);
    const commentData: any[] = await GetProjectComments(id || '');
    commentData.reverse();
    setComments(commentData);
    const statusesData = await GetProjectStatuses();
    setStatuses(statusesData);
    const contactInfoData = await getContact(projectData['ContactInfoID']);
    setContactInfo(contactInfoData)
    const clientContactData = await getClientContact(projectData['ClientID']);
    setClientContacts(clientContactData);
    const invoiceStatusData = await GetInvoiceStatuses();
    setInvoiceStatuses(invoiceStatusData);
  }

  const handleInvoiceSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    const data = {
      Name: formData.get('invoiceName'),
      Amount: formData.get('amount'),
      PurchaseOrder: formData.get('purchaseReferenceNumber'),
      Description: formData.get('invoiceDescription'),
      IssueDate: issueDate?.toISOString(),
      ContactInfoID: formData.get('invoiceContact'),
      StatusID: formData.get('status'),
      TypeID: '1',
      ProjectID: project ? project['ProjectID'] : '',
    }
    await CreateInvoices(data);
    setOpenInvoiceCreate(false);
    setIssueDate(null);
    FetchData();
  }

  const handleCommentSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    const data = {
      ProjectID: id,
      CommentBy: JSON.parse(localStorage.getItem('user')||'')?.displayName,
      CommentDate: moment(Date.now()).toISOString(),
      Text: formData.get('description')
    }
    await CreateComment(data);
    setOpenCommentCreate(false);
    FetchData();
  }

  const handleStatusChange = (event: SelectChangeEvent) => {
    const { target: { value} } = event;
    
    if(project) {
      UpdateProject(project['ProjectID'], {StatusID: value});
      FetchData();
    }
  }

  return (
    <>
      {(project && client) ? (
      <Grid padding={8} container>
        <Grid item xs={12}>{id}</Grid>
        <Grid item xs={10}>
          <h2>{project['Name']}</h2>
        </Grid>
        <Grid item padding={1} xs={2}>
          <AddProjectModal edit project={project}/>
        </Grid>
        <Grid item padding={1} xs={12}><span dangerouslySetInnerHTML={{__html: (project['Description'] || '')}}></span></Grid>
        <Grid item padding={1} paddingY={2} marginTop={2} style={{backgroundColor: '#F2F2F2'}} xs={12}>
          <span>Project Information</span>
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={6}>
          <Grid xs={2}><strong className="Project_Value_Lable">Client</strong></Grid>
          <Grid xs={3}>{client['Name']}</Grid>
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={6}>
          <Grid xs={2}><strong className="Project_Value_Lable">Client Contact</strong></Grid>
          <Grid xs={3}>{contactInfo? contactInfo['Name']: ''}</Grid>
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={6}>
          <Grid xs={2}>
            <strong className="Project_Value_Lable">Status</strong>
          </Grid>
          <Grid xs={3}>
            <FormControl size='small'>
              <Select
                id="status" name="status" value={project && project['StatusID']} required fullWidth
                onChange={handleStatusChange}
              >
                {statuses && statuses.map((status, index) => (
                  <MenuItem key={index} value={status.StatusID}>{status.Name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={6}>
          <Grid xs={2}>
            <strong className="Project_Value_Lable">Price</strong>
          </Grid>
          <Grid xs={3}>
            {(Number.parseFloat(project['Price']||0).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }))}
          </Grid>
        </Grid>
        <Grid item padding={1} paddingY={2} marginTop={2} style={{backgroundColor: '#F2F2F2'}} xs={12}>
          Project Dates
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={6}>
          <Grid xs={2}>
            <strong className="Project_Value_Lable">First Contact</strong>
          </Grid>
          <Grid xs={3}>
            {project['DateFirstContact'] && moment(project['DateFirstContact']).format('l')}
          </Grid>
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={6}>
          <Grid xs={2}>
            <strong className="Project_Value_Lable">Date for UAT</strong>
          </Grid>
          <Grid xs={3}>
            {project['DateForUat'] && moment(project['DateForUat']).format('l')}
          </Grid>
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={6}>
          <Grid xs={2}>
            <strong className="Project_Value_Lable">Date Started</strong>
          </Grid>
          <Grid xs={3}>
            {project['DateStarted'] && moment(project['DateStarted']).format('l')}
          </Grid>
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={6}>
          <Grid xs={2}>
            <strong className="Project_Value_Lable">Last Follow Up</strong>
          </Grid>
          <Grid xs={3}>
            {project['DateLastFollowUp'] && moment(project['DateLastFollowUp']).format('l')}
          </Grid>
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={12}>
          <Grid xs={2}>
            <strong className="Project_Value_Lable">Date Completed</strong>
          </Grid>
          <Grid xs={3}>
            {project['DateCompleted'] && moment(project['DateCompleted']).format('l')}
          </Grid>
        </Grid>
        <Grid item padding={1} paddingY={2} marginTop={2} style={{backgroundColor: '#F2F2F2'}} xs={12}>
          Project Team
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={6}>
          <Grid xs={2}>
            <strong className="Project_Value_Lable">Sales Lead</strong>
          </Grid>
          <Grid xs={3}>
            {project['SalesLead']}
          </Grid>
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={6}>
          <Grid xs={2}>
            <strong className="Project_Value_Lable">Project Lead</strong>
          </Grid>
          <Grid xs={3}>
            {project['ProjectLead']}
          </Grid>
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={6}>
          <Grid xs={2}>
            <strong className="Project_Value_Lable">Business Analyst</strong>
          </Grid>
          <Grid xs={3}>
            {project['BusinessAnalyst']}
          </Grid>
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={6}>
          <Grid xs={2}>
            <strong className="Project_Value_Lable">Lead Developer</strong>
          </Grid>
          <Grid xs={3}>
            {project['LeadDeveloper']}
          </Grid>
        </Grid>
        <Grid item container padding={1} className="Project_Table_Item" xs={12}>
          <Grid xs={2}>
            <strong className="Project_Value_Lable">Developers</strong>
          </Grid>
          <Grid xs={4}>
            {project['Developers']}
          </Grid>
        </Grid>
        <Grid item padding={1} paddingY={2} marginTop={2} style={{backgroundColor: '#F2F2F2'}} xs={12}>
          <div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-between'}}>
            <span>Invoices</span>
            <Button variant="contained" color="primary" onClick={()=>setOpenInvoiceCreate(!openInvoiceCreate)}><AddCircleIcon /> Add Invoice</Button>            
          </div>
          {openInvoiceCreate && (
            <>
             <Box component="form" onSubmit={handleInvoiceSubmit}> 
              <Grid container>
                <Grid item padding={1} xs={6}>
                  <TextField size="small" id="invoiceName" label="Invoice Name" name="invoiceName" required fullWidth/>
                </Grid>
                <Grid item padding={1} xs={6}>
                  <FormControl size='small' fullWidth>
                    <InputLabel id="status-lable">Invoice Contact</InputLabel>
                    <Select
                      labelId="status-label"
                      id="invoiceContact" label="Invoice Contact" name="invoiceContact" required
                    >
                      {clientContacts && clientContacts.map((contact, index) => (
                        <MenuItem key={index} value={contact.ContactInfoID}>{contact.Name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item padding={1} xs={6}>
                  <TextField size="small" id="invoiceDescription" label="Invoice Description" name="invoiceDescription" fullWidth multiline minRows={3} />
                </Grid>
                <Grid item padding={1} xs={6}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Date Started"
                      value={issueDate}
                      onChange={(value) => {
                        setIssueDate(value);
                      }}
                      renderInput={(params) => <TextField size="small" {...params} required fullWidth/>}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item padding={1} xs={6}>
                  <TextField size="small" id="amount" label="Amount" name="amount" required fullWidth/>
                </Grid>
                <Grid item padding={1} xs={6}>
                  <TextField size="small" id="purchaseReferenceNumber" label="Purchase Reference Number" name="purchaseReferenceNumber" required fullWidth/>
                </Grid>
                <Grid item padding={1} xs={6}>
                  <FormControl size='small' fullWidth>
                    <InputLabel id="status-lable">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      id="status" label="Status" name="status" required
                    >
                      {invoiceStatuses && invoiceStatuses.map((status, index) => (
                        <MenuItem key={index} value={status.StatusID}>{status.Name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item padding={1} xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Button variant="outlined" type="submit" color="secondary" style={{marginRight: '1em'}} >Save New Invoice</Button>
                  <Button variant="outlined" color="secondary" onClick={()=>{window.confirm("Confirm Cancellation") && setOpenInvoiceCreate(false)}}>Cancel</Button>
                </Grid>
              </Grid>
             </Box>
            </>
          )}
        </Grid>
        <Grid item padding={1} xs={12}>
          <InvoiceTable rows={invoices} invoiceStatuses={invoiceStatuses} />
        </Grid>
        <Grid item padding={1} xs={12} marginTop={2} style={{backgroundColor: '#F2F2F2'}}>
          <div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-between'}}>
            <span>Comments</span>
            <Button variant="contained" color="primary" onClick={()=>setOpenCommentCreate(!openCommentCreate)}><AddCircleIcon /> Add Comment</Button>
          </div>
          {openCommentCreate && (
            <>
              <Box component="form" onSubmit={handleCommentSubmit}> 
                <TextField id="description" name="description" fullWidth multiline minRows={3} style={{marginTop: '1em', marginBottom: '1em'}}/>
                <Button variant="outlined" type="submit" color="secondary">Save Comment</Button>
              </Box>
            </>
          )}
        </Grid>
        <Grid item padding={1} xs={12}>
          {comments.map((comment, index) => (
            <CommentCard comment={comment} FetchData={FetchData}/>
          ))}
        </Grid>
      </Grid>
      ):(
        <>
        </>
      )}
    </>
  );
}