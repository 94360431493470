import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

interface Data {
  ProjectID: number;
  ClientID: number;
  Name: string;
  Price: number;
  StatusID: number;
  DateStarted: Date;
  Completed: Date;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'ProjectID',
    label: 'ID',
    disablePadding: true,
    numeric: false,
  },
  {
    id: 'Name',
    label: 'Name',
    disablePadding: true,
    numeric: false,
  },
  {
    id: 'StatusID',
    label: 'Status',
    disablePadding: true,
    numeric: false,
  },
  {
    id: 'Price',
    label: 'Price',
    disablePadding: false,
    numeric: true,
  },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | Date},
  b: { [key in Key]: number | string | Date },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface TableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

function NewTableHead(props: TableHeadProps) {
  const { order, orderBy, onRequestSort } = props;
  
  const createSortHandler =
  (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.disablePadding? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order: false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id && (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface TableProps {
  rows: Data[];
  clients: Array<any> | undefined;
  statuses: Array<any> | undefined;
  activeFilters: {statuses: any, client: any};
}

export default function ProjectsTable(props: TableProps) {
  const { rows, clients, statuses, activeFilters } = props;
  const [filteredRows, setFilteredRows] = useState<Data[]>(rows);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('StatusID');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate()
  useEffect(() => {
    setFilteredRows(rows.filter(el => activeFilters.statuses.includes(el['StatusID'])).filter(el => {return activeFilters.client?  el['ClientID'] === activeFilters.client: true}));
  }, [rows, activeFilters])
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event: React.MouseEvent<unknown>, Id: number) => {
    const path = `/project/${Id}`;
    navigate(path)
  };

  const GetClientName = (id: number) => {
    if(clients){
      const client = clients.find(el => el.ClientID === id);
      return client.Name
    }
  }

  const GetStatusName = (id: number) => {
    if(statuses) {
      const status = statuses.find(el => el.StatusID === id);
      return status.Name;
    }
  }

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
        >
          <NewTableHead 
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredRows.slice().sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row.ProjectID)}
                tabIndex={-1}
                key={row.ProjectID}
              >
                <TableCell>{row.ProjectID}</TableCell>
                <TableCell component="th" scope="row" padding="none" >{GetClientName(row.ClientID)} - {row.Name}</TableCell>
                <TableCell align="left">{GetStatusName(row.StatusID)}</TableCell>
                <TableCell align="right">{(row.Price||0).toLocaleString('en-AU', { style: 'currency', currency: 'AUD', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
      />    
    </Box>
  ) 
}
