import axios from "axios";
import { API_URL } from "../enviroment";
import { getToken } from "../service/firebase";

export async function GetClients() {
  const res = await axios({
    method: 'get',
    url: `${API_URL}/clients`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    }
  });
  return res.data;
}

export async function GetClient(id: string) {
  const res = await axios({
    method: 'get',
    url: `${API_URL}/clients/${id}`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    }
  });
  return res.data;
}