import { LocalizationProvider, DatePicker } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import DateAdapter from '@mui/lab/AdapterMoment';
import React, { FormEvent, useState } from 'react';
import { Moment } from 'moment';
import { UpdateInvoice } from '../util/InvoiceApi';

interface EditIvoiceProps {
  open: boolean;
  setOpen: any;
  invoice: any;
  states: any[];
}

export default function EditInvoiceModal(props: EditIvoiceProps) {

  const { open, setOpen, invoice, states} = props;
  const [invoiceDate, setInvoiceDate] = useState<Moment | null>(null);
  const handleClose = () => {
    setOpen(false)
    setInvoiceDate(null);
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    const data = {
      Name: formData.get('invoiceName'),
      Amount: formData.get('invoiceAmount'),
      PurchaseOrder: invoice['PurchaseOrder'],
      Description: formData.get('invoiceDescription'),
      IssueDate: invoiceDate?.toISOString() || invoice['IssueDate'],
      ContactInfoID: invoice['ContactInfoID'],
      StatusID: formData.get('invoiceStatus') || invoice['StatusID'],
      TypeID: '1',   
    }
    UpdateInvoice(invoice['InvoiceID'],data);
    handleClose();
  }
  
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle>Edit Invoice</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container>
            <Grid item padding={1} xs={12}>
              <TextField size="small" id="invoiceName" label="Name" name="invoiceName" defaultValue={(invoice && invoice['Name']) || ''} fullWidth/>
            </Grid>
            <Grid item padding={1} xs={12}>
              <TextField multiline minRows={3}  id="invoiceDescription" label="Description" name="invoiceDescription" defaultValue={(invoice && invoice['Description']) || ''} fullWidth />
            </Grid>
            <Grid item padding={1} xs={12}>
              <TextField size="small" id="invoiceAmount" label="Amount" name="invoiceAmount" defaultValue={(invoice && invoice['Amount']) || ''} fullWidth />
            </Grid>
            <Grid item padding={1} xs={12}>
              <FormControl size='small' fullWidth>
                <InputLabel id="status-lable">Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="invoiceStatus" label="Status" name="invoiceStatus" required
                  defaultValue={invoice && invoice['StatusID']}
                >
                  {states && states.map((status, index) => (
                    <MenuItem key={index} value={status.StatusID}>{status.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item padding={1} xs={12}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      label="Invoice Date"
                      value={invoiceDate || (invoice && invoice['IssueDate'])}
                      onChange={(value) => {
                        setInvoiceDate(value);
                      }}
                      renderInput={(params) => <TextField size="small" {...params} required fullWidth/>}
                    />
                  </LocalizationProvider>
                </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">Save & Exit</Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}