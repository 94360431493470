import { Button, Container, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProjectsTable from "../components/ProjectsTable";
import AddProjectModal from "../components/AddProjectModal";
import { GetProjects, GetProjectStatuses } from "../util/ProjectApi";
import { GetClients } from "../util/ClientApi";
import { CheckBox } from "@mui/icons-material";

type Status = {
  StatusID: number;
  Name: string;
}

type Clients = {
  ClientID: number;
  Name: string;
}

export default function Projects() {
  const [selectedStatus, setStatus] = useState<any[]>([40,50,60,70,80,90]);
  const [statusDisylay, setStatusDisplay] = useState<string[]>([]);
  const [client, setClient] = useState('');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [statuses, setStatuses] = useState<Status[]>();
  const [clients, setClients] = useState<Clients[]>();
  const [filters, setFilters] = useState<any>({statuses: selectedStatus, client: ''});
  const ClearFilters = () => {
    setStatus([40,50,60,70,80,90]);
    setClient('');
    setFilteredRows(rows);
    setFilters({statuses: [40,50,60,70,80,90], client:''});
  }

  useEffect(()=> {
    FetchData();
  },[]);
  
  const FetchData = async () => {
    await FetchProjects();
    await FetchStatus();
    await FetchClients();
    ApplyFilters();
  }

  const SearchProjects = (value: string) => {
    if(value.length > 3){
      ApplyFilters();
      setFilteredRows( rows.filter(el => {
        let match = false;
        let values: Array<any> = Object.values(el);
        for(let i = 0; i < values.length; i++) 
        {
          if((values[i]+"").toLowerCase().includes(value.toLowerCase())){
            match = true;
          }
        }
        return match;
      }));
    } else {
      setFilteredRows(rows);
    }
  }

  const FetchProjects = async () => {
    const data = await GetProjects();
    setRows(data);
    setFilteredRows(data);
  }

  const FetchStatus = async () => {
    const data = await GetProjectStatuses();
    setStatuses(data);
  }

  const FetchClients = async () => {
    const data = await GetClients();
    setClients(data); 
  }

  const handleStateChange = (event: SelectChangeEvent<typeof selectedStatus>) => {
    const {
      target: { value },
    } = event;
    console.log(value)
    setStatus(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  const ApplyFilters = async  () => {
    // let tmpRows = rows;

    // if(selectedStatus.length>0)
    //   tmpRows = tmpRows.filter(el => selectedStatus.includes(el['StatusID']));
    // if(client)
    //   tmpRows = tmpRows.filter(el => el['ClientID'] === client);

    // setFilteredRows(tmpRows);
    setFilters({statuses: selectedStatus, client:client});
  }
  

  const getSelectedStatus = (selected: any) => {
    const displaylist: string[] = [];
    
    for(var i = 0; i < selected.length; i++) {
      displaylist.push(statuses?.find(el => el.StatusID === selected[i])?.Name || '');
    }
    
    return displaylist.join(', ');
  }

  return (
    <>
      <Container>
        <Grid container marginTop={5}>
          <Grid container marginBottom={2}>
            <Grid item xs={10} paddingRight={2}>
              < TextField size="small" fullWidth variant="outlined" onChange={(event) => SearchProjects(event.target.value)} placeholder="Seach Projects..."/>
            </Grid>
            <Grid item xs={2}>
              <AddProjectModal statuses={statuses} clients={clients}/>
            </Grid>
          </Grid>
          <Grid container marginBottom={2}>
            <Grid item xs={4} paddingRight={2}>
              <FormControl size='small' fullWidth>
                <InputLabel id="status-lable">Status</InputLabel>
                <Select
                  labelId="status-label"
                  value={selectedStatus}
                  label="Status"
                  renderValue={(selected) => getSelectedStatus(selected)}
                  multiple
                  onChange={handleStateChange}
                >
                  {statuses && statuses.map((status, index) => (
                    <MenuItem key={index} value={status.StatusID}>
                      <ListItemText primary={status.Name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} paddingRight={2}>
              <FormControl size='small' fullWidth>
                <InputLabel id="client-label">Client</InputLabel>
                <Select
                  labelId="client-label"
                  value={client}
                  label="Client"
                  onChange={(event) => setClient(event.target.value)}
                >
                  {clients && clients.map((status, index) => (
                    <MenuItem key={index} value={status.ClientID}>{status.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} paddingRight={2}>
              <Button onClick={ApplyFilters} variant="contained">Apply</Button>
            </Grid>
            <Grid item xs={1}>
              <Button variant="outlined" color="secondary" onClick={()=> ClearFilters()}>Clear</Button>
            </Grid>
          </Grid>
        </Grid>
        <ProjectsTable rows={filteredRows} clients={clients} statuses={statuses} activeFilters={filters}/>
      </Container>
    </>
  );
  
}