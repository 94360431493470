import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { GoogleLogin} from 'react-google-login';
import { CLIENT_ID } from '../enviroment';
import { onSignIn } from '../service/firebase';
import { AuthContext } from '../util/AuthContext';


export default function Login() {
  const { dispatch } = useContext(AuthContext);

  const onSuccess = (res: any) => {
    onSignIn(res, dispatch);
  }

  const onFail = (res: any) => {
    console.log('Failed to Login' ,res)
  }

  return (
    <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <GoogleLogin clientId={CLIENT_ID} onSuccess={onSuccess} onFailure={onFail} isSignedIn={true} />
    </Box>
  );
}