import axios from "axios";
import { API_URL } from "../enviroment";
import { getToken } from "../service/firebase";

export async function getContact(id: string) {
  const res = await axios({
    method: 'get',
    url: `${API_URL}/contactinfo/${id}`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    }
  })

  return res.data;
}

export async function getClientContact(id: string) {
  const res = await axios({
    method: 'get',
    url: `${API_URL}/contactinfo/client/${id}`,
    headers: {
      'Authorization': 'Bearer ' + await getToken(),
    }
  });
  return res.data;
}
